@tailwind base;
@tailwind components;
@tailwind utilities;

/* Directly targeting checkbox input for scaling */
@layer components {
  input[type='checkbox'] {
    @apply transform; /* Use Tailwind utility to ensure consistency */
    scale: 1.2; /* Scale up checkbox */
  }

  /* Custom styling for number input to remove arrows */
  input[type='number'] {
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Modern browsers */
    margin: 0; /* Ensures consistent styling */
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .container-fluid {
    padding: 10px 1rem;
  }
  .input-field {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    color: #000000;
    font-size: 1.2rem;
    border-radius: 10px;
    padding: 0.85rem 2.5rem;
    width: 100%;
    transition: border-color 0.2s ease, box-shadow 0.2s ease; /* Transition for focus effect */
  }

  .input-field:focus {
    border-color: #3b82f6; /* Tailwind's border-blue-500 */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2); /* Focus ring effect */
  }

  .register-input {
    padding: 1rem;
    border: 1px solid #dcdbdb;
    border-radius: 4px;
    margin: 0.25rem 0px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

/* Company Scrolling Images */
.companies-image-container {
  width: 100%;
  overflow: hidden;
  max-width: 1200px;
}

.companies-image-row {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}

.company-images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.company-images img {
  padding: 0.25rem 1rem;
  object-fit: cover;
}

@keyframes scrollImages {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 1024px) {
  .company-images {
    animation: scrollImages 15s linear infinite;
  }
}

ol.list-lower-alpha {
  list-style-type: lower-alpha;
}

ol.list-lower-roman {
  list-style-type: lower-roman;
}

ol > li::marker {
  font-weight: bold;
}

/* body {
  max-width: 1550px;
  margin: auto;
} */
